const initialState = {
  productNames: [],
  locales: [],
  loadedStatus: 'idle',
};

export function customDataReducer(state = initialState, action) {
  if (action.type === 'SET_CUSTOM_DATA') {
    return { ...state, ...action.payload, loadedStatus: 'loaded' };
  }

  return state;
}

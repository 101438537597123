import { useState, useEffect } from 'react';

export const useDeeplinkLocalization = (initialValues = []) => {
  const [deeplinkLocalization, setDeeplinkLocalization] = useState(initialValues);

  useEffect(() => {
    setDeeplinkLocalization(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.length]);

  const handleAddLocalization = () => {
    const deeplinkLocalizationId = Math.random();

    setDeeplinkLocalization((state) => [...state, { id: deeplinkLocalizationId }]);
  };

  const handleRemoveLocalization = (id) => {
    setDeeplinkLocalization((state) => state.filter((localize) => localize.id !== id));
  };

  const handleAddLocale = (value, id) => {
    setDeeplinkLocalization((state) =>
      state.map((localize) => {
        if (localize.id === id) {
          return { ...localize, locale: value };
        }

        return localize;
      })
    );
  };

  return {
    deeplinkLocalization,
    handleAddLocalization,
    handleRemoveLocalization,
    handleAddLocale,
  };
};

import { useEffect } from 'react';
import { LoadingPage } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';

export const LoaderComponent = ({ children }) => {
  const { loadedStatus } = useSelector((state) => state.customData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'FETCH_CUSTOM_DATA' });
  }, [dispatch]);

  if (loadedStatus === 'idle') {
    return <LoadingPage />;
  }

  return children;
};

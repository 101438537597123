import { useSelector } from 'react-redux';
import { useMutation, useRedirect, useNotify } from 'react-admin';
import * as Sentry from '@sentry/react';
import { ValidationError } from 'utils/ValidationError';

/* @Hooks */
import { useCallback } from 'react';

/* @Helpers */
import { validateShortIdUnique, mapFormValues, localizationCleaning } from '../helpers';

export function useUrlEdit(props) {
  const [mutate] = useMutation();
  const notify = useNotify();
  const redirect = useRedirect();
  const { successMessage, currentUrlId, deeplinkLocalization } = props;
  const currentUrlShortId = useSelector((state) => state.admin?.resources?.urls?.data[currentUrlId]?.shortId ?? null);

  const save = useCallback(
    async (values) => {
      if (currentUrlShortId !== values.shortId) {
        try {
          await validateShortIdUnique(values.shortId);

          return {
            shortId: 'ShortId already used',
          };
        } catch (error) {
          // skip error
        }
      }

      const clearedValues = localizationCleaning(values, deeplinkLocalization);

      try {
        await mutate(
          {
            type: 'update',
            resource: 'urls',
            payload: { data: mapFormValues(clearedValues), id: currentUrlId },
          },
          { returnPromise: true }
        );
      } catch (e) {
        if (e instanceof ValidationError) {
          notify(`${e.message} ${e.info.shortId?.join(' ') ?? ''}`);
        } else {
          Sentry.captureException(e);
        }

        return false;
      }

      redirect('/urls');
      notify(successMessage);

      return true;
    },
    [mutate, redirect, notify, successMessage, currentUrlShortId, currentUrlId, deeplinkLocalization]
  );

  return save;
}

import { call, all, takeEvery, put } from 'redux-saga/effects';

/* @Helpers */
import { request } from 'utils/request';

function* fetchCustomData() {
  const [productNames, locales] = yield all([
    call(request, '/urls/product-name/list'),
    call(request, '/urls/locale/list'),
  ]);

  yield put({ type: 'SET_CUSTOM_DATA', payload: { productNames, locales } });
}

export function* customDataSaga() {
  yield takeEvery('FETCH_CUSTOM_DATA', fetchCustomData);
}

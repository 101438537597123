/* @Helpers */
import encodeUrl from 'encodeurl';
import { request } from 'utils/request';

export async function validateShortIdUnique(shortId) {
  const requestUrl = `show/short-id/${shortId}`;

  await request(requestUrl);
}

export function arrayToSelect(data) {
  return data.map((item) => ({ id: item, name: item }));
}

export function mapFormValues(values) {
  const { url, mainCta, secondaryCta, ...restValues } = values;
  const urlData = { ...restValues };

  if (url) {
    urlData.url = encodeUrl(url);
  }

  // mapping for backend format
  urlData.mainCtaUrl = mainCta?.url;
  urlData.mainCtaTitle = mainCta?.title;
  urlData.secondaryCtaUrl = secondaryCta?.url;
  urlData.secondaryCtaTitle = secondaryCta?.title;

  if (Array.isArray(urlData.locales)) {
    urlData.locales = urlData.locales.map((locale) => mapFormValues(locale));
  }

  return urlData;
}

export function localizationCleaning(values, localization) {
  const editedLocales = localization.map(({ locale }) => locale);
  const locales = values?.locales?.filter(({ locale }) => editedLocales.includes(locale)) ?? null;

  return {
    ...values,
    locales,
  };
}

export function getCurrentDeeplinkLocalization(currentUrlId) {
  return function (state) {
    const locales = state.admin?.resources?.urls?.data[currentUrlId]?.locales;

    if (!locales) {
      return [];
    }

    return locales.map(({ locale }) => ({ id: locale, locale }));
  };
}

export function handleCopyLinkToClipboard(inputId, urlRoot) {
  const inputElement = document.getElementById(inputId);

  if (!urlRoot.endsWith('/')) {
    urlRoot += '/';
  }

  try {
    navigator.clipboard.writeText(urlRoot + inputElement.value);
  } catch (err) {
    console.error('Failed to copy to clipboard:', err);
  }
}

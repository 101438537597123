import { useMemo } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { FilterList, FilterListItem } from 'react-admin';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

function createFilterFromArray(productNames) {
  return productNames.map((productName) => ({
    id: productName,
    name: productName,
  }));
}

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
  },
}))(MuiCard);

export const UrlListAside = () => {
  const { productNames } = useSelector((state) => state.customData);
  const productNamesFilter = useMemo(() => createFilterFromArray(productNames), [productNames]);

  return (
    <Card>
      <CardContent>
        <FilterList label="Products" icon={<LibraryBooksIcon />}>
          {productNamesFilter.map(({ id, name }) => (
            <FilterListItem key={id} label={name} value={{ productName: name }} />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

import { useMemo } from 'react';
import { List, TextField, DateField, Datagrid, EditButton, ListContextProvider, useListContext } from 'react-admin';
import { CopyUrlField } from './CopyUrlField';
import { UrlListAside } from './UrlListAside';

function getSelectedData(data = {}, filters = {}) {
  if (!filters.productName) {
    return data;
  }

  const result = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (value?.productName === filters.productName) {
      result[key] = value;
    }
  });

  return result;
}

export const DataGridWithCustomFilter = (props) => {
  const listContext = useListContext();
  const { filterValues } = props;
  const { data } = listContext;
  const selectedData = useMemo(() => getSelectedData(data, filterValues), [filterValues, data]);
  const ids = useMemo(() => Object.keys(selectedData).reverse(), [selectedData]);

  return (
    <>
      <ListContextProvider value={{ ...listContext, data: selectedData, ids }}>
        <Datagrid {...props} data={selectedData} ids={ids}>
          <CopyUrlField target="_blank" label="Redirect Page" source="redirectPage" sortable={false} />
          <DateField
            options={{ year: 'numeric', month: 'long', day: 'numeric' }}
            label="Last Update"
            source="updatedAt"
            sortable={false}
          />
          <TextField label="User Name" source="user.name" sortable={false} />
          <EditButton />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

export const UrlList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} pagination={false} aside={<UrlListAside />}>
      <DataGridWithCustomFilter />
    </List>
  );
};

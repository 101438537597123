import { required, regex, maxLength } from 'react-admin';

/* @Constants */
import { DEEP_LINK, URL } from './regex';

export const productNameValidation = required('Product name is required');
export const localeValidation = required('Locale is required');
export const shortIdValidation = [
  required('Macpaw.app url is required filed'),
  regex(/^[\w-]+$/, 'Short link should contain A-Z a-z 0-9 - _ characters only'),
  maxLength(100, 'Short link is too long. Should be up to 100 characters only'),
];
export const deepLinkValidation = regex(DEEP_LINK, 'deep link is not valid');
export const redirectUrlValidation = regex(URL, 'redirect url is not valid');
export const secondaryCtaUrlValidation = regex(URL, 'secondary CTA url is not valid');
export const mainCtaUrlValidation = regex(URL, 'main CTA url is not valid');

/* @Utils */
import { request } from 'utils/request';

const dataProvider = {
  async getList(resource) {
    const url = resource;
    const data = await request(url);

    return {
      data,
      total: data.length,
    };
  },
  async getOne(resource, params) {
    const url = `${resource}/${params.id}`;
    const data = await request(url);

    return { data };
  },
  async update(resource, { id, data }) {
    const url = `/${resource}/${id}`;
    const method = 'PATCH';

    const updatedData = await request(url, { method, body: data });

    return { data: updatedData };
  },
  async create(resource, { data }) {
    const url = `/${resource}`;
    const method = 'POST';

    const updatedData = await request(url, { method, body: data });

    return { data: updatedData };
  },
  async delete(resource, { id, previousData }) {
    const url = `/${resource}/${id}`;
    const method = 'delete';

    await request(url, { method });

    return { data: previousData };
  },
};

export { dataProvider };

import { useState, useEffect } from 'react';
import { initGoogleAuth } from 'services/googleAuth';
import { useLogin, useNotify, Notification, LoadingPage } from 'react-admin';
import { Typography, Button, Grid, CssBaseline, Paper, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* @Images */
import MacPawLogo from 'images/macpaw-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  formGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

export const LoginPage = () => {
  const [loginState, setLoginState] = useState('idle');
  const classes = useStyles();
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    initGoogleAuth().then(() => {
      setLoginState('init');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    setLoginState('loading');

    try {
      await login({}, '/urls');
      setLoginState('success');
    } catch (error) {
      setLoginState('error');
      notify('Could not login user', 'warning');
    }
  };

  if (loginState === 'idle') {
    return <LoadingPage />;
  }

  return (
    <Grid component="main" container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} square elevation={6} className={classes.formGrid}>
        <div className={classes.paper}>
          <Avatar src={MacPawLogo} className={classes.logo} alt="MacPaw company logo" />
          <Typography component="h1" variant="h5" className={classes.title}>
            Login to MacPaw.app
          </Typography>
          <form onSubmit={submit}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={loginState === 'loading'}
            >
              Continue with MacPaw email
            </Button>
          </form>
        </div>
      </Grid>
      <Notification />
    </Grid>
  );
};

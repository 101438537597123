import { SaveButton, Toolbar } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const UrlCreateToolbar = ({ handleAddLocalization, ...props }) => {
  const { hasValidationErrors, hasSubmitErrors } = useFormState();
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton
        label="Save"
        redirect="urls"
        className={classes.saveButton}
        disabled={hasValidationErrors || hasSubmitErrors}
      />
      <>
        <Button variant="contained" color="secondary" onClick={handleAddLocalization}>
          <Add /> localization
        </Button>
      </>
    </Toolbar>
  );
};

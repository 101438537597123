export const tryGetAuthInstance = () => {
  let GoogleAuth;

  try {
    // eslint-disable-next-line no-undef
    GoogleAuth = gapi.auth2.getAuthInstance();
  } catch (error) {
    const initializeError = new Error('Google auth is not initialize, please initialize initGoogleAuth first');

    throw new Error(initializeError);
  }

  return GoogleAuth;
};

export const mappingAuthInfo = (authResponse) => ({
  idToken: authResponse.id_token,
  accessToken: authResponse.access_token,
});

export const isGoogleUserSignIn = () => {
  const GoogleAuth = tryGetAuthInstance();

  return GoogleAuth.isSignedIn.get();
};

/* @Helpers */
import { RequestError } from 'utils/RequestError';
import { ValidationError } from 'utils/ValidationError';
import { createRequestOptions, createUrlWithQuery } from './helpers';

/* @Errors */

/**
 *
 * @param {Object} params - params for make request
 * @param {string} url
 * @param {string} params.method
 * @param {object} params.headers
 * @param {BodyInit} params.body
 * @param {object|string} params.query
 */
export const request = async (url, params = {}) => {
  const { query, ...options } = params;
  const urlWithQuery = createUrlWithQuery(url, query);
  const requestOptions = createRequestOptions(options);

  const response = await fetch(urlWithQuery, requestOptions);
  const body = await response.text();

  let json;
  try {
    json = JSON.parse(body);
  } catch (e) {
    // not json, no big deals
  }

  if (!response.ok && json) {
    throw new ValidationError(json);
  }

  if (!response.ok) {
    throw new RequestError(response.statusText, response.status);
  }

  return json;
};

/* @Config */
import { BASE_PATH } from 'constants/config';

import { AdminUI, Resource, AdminContext } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';

/* @Components */
import { login } from 'components/login';
import { urls } from 'components/urls';
import { Loader } from 'components/loader';

/* @Providers */
import { authProvider } from 'providers/auth';
import { dataProvider } from 'providers/data';

/* @Store */
import { customDataReducer } from 'store/customDataReducer';
import { customDataSaga } from 'store/customDataSaga';

const history = createHistory({
  basename: BASE_PATH,
});

function App() {
  return (
    <AdminContext
      history={history}
      customReducers={{ customData: customDataReducer }}
      customSagas={[customDataSaga]}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Loader>
        <AdminUI {...login}>
          <Resource name="urls" {...urls} />
        </AdminUI>
      </Loader>
    </AdminContext>
  );
}

export default App;

/* eslint-disable no-undef */
/* @Utils */
import loadJsFile from 'loadjsfile';

/* @Helpers */
import { tryGetAuthInstance, isGoogleUserSignIn, mappingAuthInfo } from './helpers';

/* @Constants */
const GOOGLE_CLIENT_ID = '1059893365428-lf2u1ragbmebtss44c0u2kj935qb8ft4.apps.googleusercontent.com';
const GOOGLE_AUTH_SDK_URL = 'https://apis.google.com/js/api.js';

// https://developers.google.com/identity/sign-in/web/reference#gapiauth2clientconfig
export const initGoogleAuth = () =>
  new Promise((resolve, reject) => {
    try {
      tryGetAuthInstance();
      resolve();
    } catch (error) {
      loadJsFile(GOOGLE_AUTH_SDK_URL)
        .then(() => {
          gapi.load('client:auth2', () => {
            const initializeOptions = {
              clientId: GOOGLE_CLIENT_ID,
              scope: 'profile',
            };

            gapi.client.init(initializeOptions).then(resolve, reject);
          });
        })
        .catch(reject);
    }
  });

const signIn = () =>
  new Promise((resolve, reject) => {
    try {
      const GoogleAuth = tryGetAuthInstance();

      resolve(GoogleAuth.signIn());
    } catch (error) {
      reject(error);
    }
  });

const getGoogleAuthInfo = () =>
  new Promise((resolve, reject) => {
    try {
      const GoogleAuth = tryGetAuthInstance();

      if (!isGoogleUserSignIn()) {
        throw new Error('Can not get user data, user is sign out');
      }

      const authResponse = GoogleAuth.currentUser.get().getAuthResponse();
      const authInfo = mappingAuthInfo(authResponse);

      resolve(authInfo);
    } catch (error) {
      reject(error);
    }
  });

export const signInWithGoogleAuth = async () => {
  try {
    await signIn();
    const authInfo = await getGoogleAuthInfo();

    return {
      authInfo,
    };
  } catch (error) {
    if (error?.error) {
      throw new Error(error?.error);
    }

    throw error;
  }
};

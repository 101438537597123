export class RequestError extends Error {
  constructor(...params) {
    const errorParams = [...params];
    const status = params.pop();

    super(...errorParams);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.status = status;
  }
}

import copy from 'copy-to-clipboard';
import { useRecordContext, UrlField, useNotify } from 'react-admin';
import { IconButton, Tooltip } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export const CopyUrlField = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const { source } = props;
  const record = useRecordContext(props);

  const handleCopyUrl = () => {
    copy(record[source]);
    notify('The url has copied!');
  };

  return (
    <div>
      <Tooltip title="click to copy url">
        <IconButton className={classes.button} onClick={handleCopyUrl}>
          <FileCopy />
        </IconButton>
      </Tooltip>
      <UrlField {...props} />
    </div>
  );
};

/* @Services */
import { signInWithGoogleAuth } from 'services/googleAuth';

/* @Utils */
import { request } from 'utils/request';

const authProvider = {
  async login() {
    const { authInfo } = await signInWithGoogleAuth();
    const url = '/auth/by-token';
    const body = { accessToken: authInfo.accessToken };

    await request(url, { method: 'post', body });
  },
  async checkAuth() {
    const url = '/auth/check-auth';

    await request(url);
  },
  async logout() {
    const url = '/auth/logout';

    await request(url);
  },
  async getIdentity() {
    const url = '/auth/user';

    const { id, name: fullName, avatar } = await request(url);

    return Promise.resolve({ id, fullName, avatar });
  },
  checkError(error) {
    if (error?.status === 401) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  // authorization
  getPermissions: () => Promise.resolve(),
};

export { authProvider };

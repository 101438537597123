/* @Constants */
import { SENTRY_DSN, APP_ENV } from 'constants/config';

import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/* @Components */
import App from './App';

/* @Styles */
import './index.css';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});

ReactDOM.render(<App />, document.getElementById('root'));
